@import '../base.scss';

.content {
    margin: 0 3vw;
}

.questions {
    margin: 4vh 3vw;
}

.p {
    font-family: $basefont;
    font-size: 110%;
    line-height: 180%;
    margin: 1.5vh;
}

.link {
    display: inline-block;
    width: auto;
    color: #b62;
    font-weight: bold;
    text-decoration: none;
    border: 2px solid #777;
    border-radius: 30px;
    background-color: #ec7;
    padding: 2px 20px;
    margin: 1vh 20px;
}
