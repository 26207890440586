
.answer {
    display: inline-block;
    width: 50vw;
    color: #b62;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    line-height: 120%;
    border: 2px solid #777;
    border-radius: 30px;
    background-color: #ec7;
    padding: 2px 20px;
    margin: -1vh 50px;
}