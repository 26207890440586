.success {
    width: 100%;
    height: 100%;
    background-color: green;
}

.failed {
    width: 100%;
    height: 100%;
    background-color: darkred;
}
