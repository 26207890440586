@import '../base.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    height: 20vh;
    width: 100vw;
}

.title {
    margin: auto auto;
    font-family: $titlefont;
    font-size: 300%;
}

.subtitle {
    margin: 0 auto auto auto;
    padding: 0 10vw;
    font-family: $titlefont;
    font-size: 140%;
}